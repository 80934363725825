import { UserContext } from "@/context/user";
import { usePubNubInit } from '@/hooks/usePubNubInit';
import { useUnreadMessages } from '@/hooks/useUnreadMessages';
import { getCustomUserObj } from '@/lib/chatUtils';
import { generateChannelId } from '@/lib/utils';
import type { IProfile } from '@/types/profile';
import type { Membership, Channel as PnChannel, Message as PnMessage, SendTextOptionParams, TextLink, User } from "@pubnub/chat";
import { Channel, Chat as PnChat } from "@pubnub/chat";
import { useRouter } from 'next/router';
import type { FC, ReactNode } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

export type SimpleUser = {
  channelName: string,
  channelPicture: string,
  id: string
}

export interface CustomObject {
  [user_id: string]: string, //channelName: string, channelPicture: string
  status: "pending" | "accepted" | "refused",
  hostId: string,
  interlocutorId: string
}

export type ChatMember = {
  user: Membership['user'],
  userId: string
}

export type CustomChannel = {
  unreadMessages?: number | string | undefined
} & PnChannel;

export type MessagesProps = { files?: SendTextOptionParams['files'], meta: any, textLinks?: TextLink[] }

export type PubnubContextType = {
  chatPubnub?: PnChat;
  userChannelsPubnub: CustomChannel[];
  channelSelected: CustomChannel | null;
  channelMembers: ChatMember[],
  isLoadingPubnub: boolean,
  historyMessagesPubnub: {
    messages?: PnMessage[];
    isMore?: boolean;
  } | null;
  setChannelSelected: (channel: CustomChannel) => void;
  createDirectConversation: (profile: IProfile, customData?: { [key: string]: string }) => Promise<CustomChannel | undefined>;
  sendMessageToChannel: (text: string, { files, meta, textLinks }: MessagesProps, channelId?: string) => void;
  updateChannel: (channelId: string, data: Record<string, any>) => Promise<CustomChannel | undefined>;
  getChannel: (channelId: string) => void;
  deleteChannel: (id: string) => void,
  joinChannel: (channelId: string) => void;
  refuseChannel: (channelId: string) => void;
  currentUser: User | undefined;
  getHost: (channel: CustomChannel) => ({ host: SimpleUser | null });
  isHost: (channel: CustomChannel) => boolean;
  markAllMessagesAsRead: () => void,
  refreshHistory: (reload?: boolean) => void;
};

const MESSAGES_TO_SHOW = 10;

export const PubnubContext = createContext<PubnubContextType>({} as PubnubContextType,);

interface PubnubProviderProps {
  children: ReactNode;
}

type THistory = {
  messages: PnMessage[] | undefined;
  isMore?: boolean | undefined;
} | null

export const PubnubProvider: FC<PubnubProviderProps> = ({ children }) => {
  const { user, ownerProfile: profile } = useContext(UserContext);
  const { chatPubnub, currentUser, setCurrentUser } = usePubNubInit(user);
  const { unreadChannels, fetchUnreadMessagesCount } = useUnreadMessages(chatPubnub);
  const [userChannelsPubnub, setUserChannelsPubnub] = useState<CustomChannel[]>([]);
  const [userMemberships, setUserMemberships] = useState<Membership[]>([]);
  const [channelSelected, setChannelSelected] = useState<CustomChannel | null>(null);
  const [historyMessagesPubnub, setHistoryMessagesPubnub] = useState<THistory>(null);
  const [channelMembers, setChannelMembers] = useState<ChatMember[]>([]);
  const [shownMessagesCount, setShownMessagesCount] = useState(10);
  const { query } = useRouter();

  useEffect(() => {
    const handleScreenFocus = async () => {
      if (!chatPubnub) {
        return
      }

      const [, { memberships: refreshedMemberships }/* , { users } */] = await Promise.all([
        fetchUnreadMessagesCount(),
        chatPubnub.currentUser.getMemberships(),
      ])
      setUserMemberships(refreshedMemberships)
    }

    handleScreenFocus()
  }, [chatPubnub, fetchUnreadMessagesCount])

  useEffect(() => setUserChannelsPubnub(userMemberships.map((m) => m.channel)), [userMemberships])

  // init all channels where current user is member
  useEffect(() => {
    if (!chatPubnub) {
      return;
    }

    if (!userChannelsPubnub?.length) {
      return;
    }

    const initChannels = async (channels: PnChannel[]) => {
      const channelsListener = async () => {
        Channel.streamUpdatesOn(channels, (channels) => {
          setUserChannelsPubnub(channels);
          if (!channelSelected) {
            return;
          }
          const selected = channels?.find(c => c?.id === channelSelected?.id);

          if (!selected) {
            return;
          }
          setChannelSelected(selected);
        })
      }

      channelsListener()
    }

    initChannels(userChannelsPubnub)
  }, [chatPubnub, userChannelsPubnub, channelSelected]);

  useEffect(() => {
    if (!chatPubnub) {
      return;
    }

    // NOT THE BEST SOLUTION TO UPDATE USER
    if (!profile?.user_id) {
      return;
    }

    const updateCurrentUser = async () => {
      let user = null;

      if (chatPubnub?.currentUser.name !== `${profile?.first_name} ${profile?.last_name}` ||
        chatPubnub.currentUser.custom?.company !== profile?.company ||
        chatPubnub.currentUser.custom?.job_role !== profile?.job_role
      ) {
        user = await chatPubnub?.updateUser(profile?.user_id, {
          name: `${profile?.first_name} ${profile?.last_name}`,
          custom: {
            _id: profile?.user_id,
            first_name: profile?.first_name,
            last_name: profile?.last_name,
            company: profile?.company,
            job_role: profile?.job_role,
          }
        })
      }

      if (profile?.profile_image_url && chatPubnub?.currentUser?.profileUrl !== profile?.profile_image_url) {
        user = await chatPubnub?.updateUser(profile?.user_id, {
          profileUrl: profile?.profile_image_url || chatPubnub?.currentUser?.profileUrl,
        })
      }

      if (!user) {
        return;
      }
      setCurrentUser(user)
    };

    updateCurrentUser()
  }, [chatPubnub, profile])

  // Create direct 1:1 conversation channel
  const createDirectConversation = async (interlocutorProfile: IProfile, customData?: { [key: string]: string }, retry = 0) => {
    if (!chatPubnub) return;

    try {
      let interlocutor = (await chatPubnub.getUser(interlocutorProfile?.user_id)) || await chatPubnub.createUser(interlocutorProfile?.user_id, {
        profileUrl: interlocutorProfile?.profile_image_url,
        name: `${interlocutorProfile?.first_name} ${interlocutorProfile?.last_name}`,
        custom: {
          _id: interlocutorProfile?._id,
          first_name: interlocutorProfile?.first_name,
          last_name: interlocutorProfile?.last_name,
          company: interlocutorProfile?.company,
          job_role: interlocutorProfile?.job_role,
        }
      });

      const customObject = {
        [`user.${user?._id as string}`]: JSON.stringify({
          channelName: currentUser?.name,
          channelPicture: currentUser?.profileUrl,
          id: user?._id
        }),
        [`user.${interlocutor.id as string}`]: JSON.stringify({
          channelName: interlocutor?.name,
          channelPicture: interlocutor?.profileUrl,
          id: interlocutor.id
        }),
      }

      const {
        channel,
        hostMembership
      } = await chatPubnub.createDirectConversation({
        user: interlocutor,
        channelId: generateChannelId([interlocutor.id, user?._id!]),
        channelData: {
          custom: {
            ...customObject,
            hostId: user?._id,
            interlocutorId: interlocutor?.id,
            status: "pending",
            ...customData
          } as CustomObject,
        },
        //TODO: matte non ci vorrebbe anche un membershipData?
      });

      setUserChannelsPubnub((prevState) => [...prevState?.filter(chat => chat.id !== channel.id), channel]);
      setUserMemberships((prevState) => [...prevState?.filter(m => m.channel.id !== channel.id), hostMembership]);
      return Object.assign({}, channel);
    } catch (err) {
      console.log('Error:', err);
      if (retry >= 2) {
        return;
      }
      createDirectConversation(interlocutorProfile, customData, retry + 1)
    }
  };

  const getChannelHandler = async (channelId: string) => {
    return userChannelsPubnub?.find(m => m?.id === channelId) ||
      (await chatPubnub?.getChannel(channelId) as CustomChannel);
  }

  const getChannel = async (channelId: string) => {
    if (!chatPubnub) return;

    const channel = await getChannelHandler(channelId);
    if (!channel) return;

    await fetchChannelHistory(channel);
    await setupChannelListener(channel);
    await updateChannelMembers(channel);
    await updateChannelCustomInfo(channel);

    setChannelSelected(channel);
  };

  const fetchChannelHistory = async (channel: CustomChannel) => {
    const historyMessages = await channel.getHistory({ count: MESSAGES_TO_SHOW });
    setHistoryMessagesPubnub(historyMessages);
    setShownMessagesCount(MESSAGES_TO_SHOW);

    const membership = userMemberships?.find(m => m?.channel?.id === channel?.id);
    if (historyMessages?.messages?.length) {
      await membership?.setLastReadMessage(historyMessages.messages[historyMessages.messages.length - 1]);
      await fetchUnreadMessagesCount();
    }
  };

  const setupChannelListener = async (channel: CustomChannel) => {
    channel.connect(async (message) => {
      updateHistoryMessages(message);
      await updateMembershipLastRead(channel, message);
    });
  };

  const updateHistoryMessages = (message: PnMessage) => {
    setHistoryMessagesPubnub((prevHistory: THistory) => {
      if (prevHistory?.messages?.find(m => m?.timetoken === message?.timetoken)) {
        return prevHistory;
      }
      return {
        ...prevHistory,
        messages: [...(prevHistory?.messages || []), message]
      };
    });
  };

  const updateMembershipLastRead = async (channel: CustomChannel, message: PnMessage) => {
    const membership = userMemberships?.find(m => m?.channel?.id === channel?.id);
    if (membership) {
      await membership.setLastReadMessage(message);
    }
  };

  const updateChannelMembers = async (channel: CustomChannel) => {
    const membersData = await channel.getMembers();
    setChannelMembers(membersData.members?.map(m => ({
      user: Object.assign({}, m.user),
      userId: m.user?.id
    })));
  };

  const updateChannelCustomInfo = async (channel: CustomChannel) => {
    const membersData = await channel.getMembers();
    const membersCustomObject = membersData?.members?.reduce((acc, m) => ({
      ...acc,
      [`user.${m.user.id}`]: JSON.stringify({
        channelName: m?.user?.name,
        channelPicture: m?.user?.profileUrl,
        id: m.user?.id
      })
    }), {});

    await updateChannel(channel.id, membersCustomObject, channel);
  };

  // Per portare il timetoken nel channel per orario ultimo messaggio e per ultimo messaggio
  const updateChannel = async (channelId: string, data: Record<string, any>, incomingChannel?: Channel) => {
    let channel = null;
    if (incomingChannel) {
      channel = incomingChannel
    } else {
      channel = await getChannelHandler(channelId);
    }

    if (!channel) return;

    if (data.lastMessageTimetoken && channel?.custom?.lastMessageTimetoken === data?.lastMessageTimetoken) {
      return;
    }

    const newChannel = await channel.update({
      custom: {
        ...channel.custom,
        ...data
      }
    })

    setChannelSelected(newChannel);
    setUserChannelsPubnub((prevState) => [...prevState?.filter(chat =>
      chat.id !== newChannel.id
    ), newChannel]);
    return newChannel;
  };

  // Send message to conversation channel
  const sendMessageToChannel = async (text: string, { files, meta, textLinks }: MessagesProps, channelId?: string | null, incomingChannel?: Channel, retry = 0) => {
    try {
      let channel = channelSelected;

      if (channelId) {
        channel = await getChannelHandler(channelId) || null;
      }

      if (incomingChannel) {
        channel = incomingChannel;
      }

      if (!channel) {
        return;
      }

      const messageSent = await channel.sendText(text || "", {
        files: !!files?.length ? files : undefined,
        meta: { ...meta, tenant: user?.tenant, channelId: channel?.id },
        textLinks
      }) as PnMessage;

      if (!messageSent) {
        return;
      }
      updateChannel(channel?.id as string, { lastMessageTimetoken: messageSent?.timetoken, lastMessage: text }, channel);

      const membership = userMemberships?.find(m => m?.channel?.id === channel?.id);

      if (!membership) {
        return;
      }
      await membership.setLastReadMessage(
        messageSent
      );
    }
    catch (err) {
      console.log('Error sending message: ', err);
      if (retry >= 2) {
        return;
      }
      sendMessageToChannel(text, { files, meta, textLinks }, channelId, incomingChannel, retry + 1)
    }
  };

  /* CHANNEL INVITATION POOLING */
  useEffect(() => {
    if (!chatPubnub) {
      return
    }

    const removeInvitationListener = chatPubnub.listenForEvents({
      channel: chatPubnub.currentUser.id,
      type: "invite",
      callback: async () => {
        console.log(`Notification: Received an invite for a new chat!`);
        const { memberships } = await chatPubnub.currentUser.getMemberships()
        setUserMemberships(memberships)
      },
    })

    return () => {
      removeInvitationListener()
    }
  }, [chatPubnub])

  const deleteChannel = useCallback((channelId: string) => {
    if (!chatPubnub) {
      return;
    };
    chatPubnub.deleteChannel(
      channelId,
      {
        soft: false
      }
    )
  }, [chatPubnub])

  const joinChannel = async (channelId: string, retry = 0) => {
    try {
      if (!chatPubnub) {
        return;
      };

      const channel = await updateChannel(channelId, {
        status: 'accepted'
      });

      await channel?.join(
        (data) => {
          console.log(
            "I joined the chat!", data
          )
        })

      const host = getCustomUserObj(channel as Channel, String(channel?.custom?.hostId), "owner");
      const interlocutor = getCustomUserObj(channel as Channel, String(channel?.custom?.interlocutorId), "owner");
      sendMessageToChannel(`Your chat request was approved, you can now talk with ${interlocutor?.channelName}.`, {
        meta: {
          receiver_name: host?.channelName,
          receiver_id: host?.id,
          sender_id: interlocutor?.id,
          sender_name: interlocutor?.channelName,
          sender_profile_image: interlocutor?.channelPicture,
          workspace_id: query?.id,
          type: "requests_accepted",
        },
      }, null, channel);
    } catch (err) {
      console.log('Error: ', err);
      if (retry >= 2) {
        return;
      }
      joinChannel(channelId, retry + 1)
    }
  }

  const refuseChannel = useCallback(async (channelId: string, retry = 0) => {
    try {
      if (!chatPubnub) {
        return;
      };

      const channel = await updateChannel(channelId, {
        status: 'refused'
      });

      // await channel?.leave();
    } catch (err) {
      console.log('Error: ', err);
      if (retry >= 2) {
        return;
      }
      refuseChannel(channelId, retry + 1)
    }
  }, [chatPubnub])

  const getHost = useCallback((channel: CustomChannel) => {
    if (!channel?.custom) {
      return { host: null };
    }
    const hostKey = Object.keys(channel?.custom)
      ?.filter((k) => k?.includes("user."))
      ?.find((k) => k?.includes(String(channel?.custom?.hostId)));

    if (!hostKey || !channel?.custom[hostKey]) {
      return { host: null };
    }

    return { host: JSON.parse(String(channel.custom?.[hostKey])) as SimpleUser };
  }, [channelSelected]);

  const isHost = useCallback((channel: CustomChannel) => {
    const { host } = getHost(channel);
    return currentUser?.id === host?.id
  }, [currentUser?.id, getHost]
  );

  const markAllMessagesAsRead = useCallback(async (retry = 0) => {
    if (!chatPubnub) return;
    try {
      await chatPubnub.markAllMessagesAsRead()
      await fetchUnreadMessagesCount()
    } catch (err) {
      console.log('Error: ', err);
      if (retry >= 2) {
        return;
      }
      markAllMessagesAsRead(retry + 1)
    }
  }, [chatPubnub, fetchUnreadMessagesCount])

  useEffect(() => {
    const disconnectFuncs = userChannelsPubnub?.filter(ch => channelSelected?.id !== ch?.id).map((ch) => {
      return ch.connect(async (message) => {
        fetchUnreadMessagesCount();
        return updateChannel(ch?.id as string, { lastMessageTimetoken: message?.timetoken, lastMessage: message?.text });
      })
    });

    return () => {
      disconnectFuncs.forEach((func) => func())
    }
  }, [userChannelsPubnub, channelSelected])

  const refreshHistory = useCallback(async (reload = false, retry = 0) => {
    if (!channelSelected) {
      return;
    }
    try {
      const messagesToFetch = reload ? shownMessagesCount : shownMessagesCount + MESSAGES_TO_SHOW;

      const historyMessages = await channelSelected?.getHistory({
        count: messagesToFetch
      });
      setHistoryMessagesPubnub(historyMessages);
      setShownMessagesCount(messagesToFetch);
    } catch (err) {
      console.log('Error: ', err);
      if (retry >= 2) {
        return;
      }
      refreshHistory(reload, retry + 1)
    }
  }, [channelSelected, shownMessagesCount]);

  // useMemo to memoize the contextValue
  const contextValue = useMemo(
    () => ({
      chatPubnub,
      userChannelsPubnub: userChannelsPubnub?.map((channel) => {
        const unread = unreadChannels?.find(unread => unread.channel.id === channel?.id);

        return {
          ...channel,
          unreadMessages: Number(unread?.count) > 100 ? '+99' : unread?.count
        } as CustomChannel
      }),
      channelSelected,
      channelMembers,
      joinChannel,
      setChannelSelected,
      createDirectConversation,
      sendMessageToChannel,
      updateChannel,
      historyMessagesPubnub,
      getChannel,
      isLoadingPubnub: !chatPubnub,
      deleteChannel,
      refuseChannel,
      currentUser,
      getHost,
      isHost,
      markAllMessagesAsRead,
      refreshHistory
    }),
    [
      chatPubnub,
      userChannelsPubnub,
      channelSelected,
      channelMembers,
      joinChannel,
      setChannelSelected,
      createDirectConversation,
      sendMessageToChannel,
      updateChannel,
      historyMessagesPubnub,
      getChannel,
      chatPubnub,
      deleteChannel,
      refuseChannel,
      currentUser,
      getHost,
      isHost,
      markAllMessagesAsRead,
      unreadChannels,
      refreshHistory
    ]
  )

  return (
    <PubnubContext.Provider
      value={contextValue}
    >
      {children}
    </PubnubContext.Provider>
  );
};
