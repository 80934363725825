import { Channel, Membership } from '@pubnub/chat';
import { useCallback, useEffect, useState } from 'react';

// Create a custom hook for unread messages
export const useUnreadMessages = (chatPubnub: any) => {
    const [unreadChannels, setUnreadChannels] = useState<
        { channel: Channel; count: number; membership: Membership }[]
    >([]);

    const fetchUnreadMessagesCount = useCallback(async (retry = 0) => {
        if (!chatPubnub) return;

        try {
            const unreadMessagesCounts = await chatPubnub.getUnreadMessagesCounts();
            setUnreadChannels(unreadMessagesCounts);
        } catch (err) {
            console.log('Err:', err);
            if (retry < 2) {
                fetchUnreadMessagesCount(retry + 1);
            }
        }
    }, [chatPubnub]);

    useEffect(() => {
        fetchUnreadMessagesCount();
    }, [fetchUnreadMessagesCount]);

    return { unreadChannels, fetchUnreadMessagesCount, unreadCount: unreadChannels?.length };
};
