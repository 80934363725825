import { type IUserMe } from '@/types/userMe';
import { CryptoModule, Chat as PnChat, User } from '@pubnub/chat';
import Pubnub from 'pubnub';
import { useEffect, useState } from 'react';

const RETRY_DELAY_IN_SECONDS = 5;
const MAXIMUM_RETRY = 10;

// Create a custom hook for PubNub initialization
export const usePubNubInit = (user: IUserMe | null) => {
    const [chatPubnub, setChatPubnub] = useState<PnChat>();
    const [currentUser, setCurrentUser] = useState<User>();

    useEffect(() => {
        if (chatPubnub || !user) return;

        const initializeChat = async (user: IUserMe, retry = 0) => {
            try {
                const chat = await PnChat.init({
                    publishKey: process.env.PUBNUB_PUB_KEY,
                    subscribeKey: process.env.PUBNUB_SUB_KEY!,
                    userId: user._id,
                    logVerbosity: true,
                    // logVerbosity: process.env.NODE_ENV !== 'production',
                    cryptoModule: CryptoModule.aesCbcCryptoModule({ cipherKey: "enjoy-sphere" }),
                    ssl: true,
                    restore: true,
                    enableEventEngine: true,
                    retryConfiguration: Pubnub.LinearRetryPolicy({ delay: RETRY_DELAY_IN_SECONDS, maximumRetry: MAXIMUM_RETRY }),
                    keepAlive: true,
                });
                setChatPubnub(chat);
                setCurrentUser(chat.currentUser);
            } catch (err) {
                console.log('Err:', err);
                if (retry < 2) {
                    initializeChat(user, retry + 1);
                }
            }
        };

        initializeChat(user);
    }, [chatPubnub, user])

    return { chatPubnub, currentUser, setCurrentUser };
};
